<template>
  <div class="text-center mt-3">
    <h1>404</h1>
    <p>The page you are trying to visit has migrated to a parallel universe.</p>
    <p><router-link :to="{name:'index'}">Take me to the home page</router-link>.</p>
  </div>
</template>

<script>
export default { name: 'NotFound' };
</script>
